import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'
export default function userSendMail() {
   
    const toast = useToast();
    const refListTable = ref(null);
    const tableColumns = [
        { key: 'id', label: 'ID', sortable: false },
       // { key: 'avatar', label: 'Avatar', sortable: false },
        { key: 'name',label:'Name', sortable: false },
        { key: 'phone',label:'Phone', sortable: false },
        { key: 'group_name',label:'Group', sortable: false },
      //  { key: 'is_active',label:'Active', sortable: false },
        
    ];
    const show = ref(false);
    const user_selected = ref([]);
    const isPublic = ref(false);
    const users = ref([]);
    const selectAlled = ref(false);
    const perPage = ref(50);
    const groupQuery = ref('');
    const totals = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const filter_group = ref('');
    const filter_zone = ref('');
    const filter_bedroom = ref('');
    const filter_bathroom = ref('');
    const filter_types = ref('');
    const filter_price_min = ref('');
     const filter_price_max = ref('');
    const filter_type = ref('property');
    const group_id = ref('');
    const property_name = ref('');
    const date_from = ref('');
    const date_to = ref('');
    //const items = ref([]);
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);
    const id = ref(0);
    const title = ref('');
    
    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
            return {
                from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totals.value,
            }
    });
    const refetchData = () => {
        currentPage.value = 1;
        fetchData();
        //refListTable.value.refresh()
    };
    watch([user_selected],()=>{
        userLists();
    });
   
    watch([date_from, date_to, group_id, property_name,perPage, searchQuery,groupQuery, filter_group, filter_zone, filter_bedroom,filter_bathroom,filter_types,filter_price_min, filter_price_max,filter_type], () => {
      
        refetchData()
    })
    watch([currentPage], () => {
       // selectAlled.value = false;
        userPageLists()
    })
    const userPageLists = ()=>{
        const offset = perPage.value*(currentPage.value-1);
        users.value.slice(offset,offset+perPage.value);

    }
    const userLists = ()=>{
        if(user_selected.value.length == 0){
            selectAlled.value = false;
        }
        console.log('user_selected',user_selected.value);
    }
    const selectAllData = ()=>{
        selectAlled.value != selectAlled.value;
        if(selectAlled.value){

           const usrs = users.value.map((item) => {
                return item.id;
                

            });
            user_selected.value =  user_selected.value.concat(usrs);
          
        }else{
            user_selected.value = [];
        }
       
    }
   
    const SubmitForm = (title)=>{
        if(user_selected.value.length == 0){
            toast({
                component: ToastificationContent,
                props: {
                  title: "Please Select User.",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
        

        }else{
            const userData = JSON.parse(localStorage.getItem('userData'));
            show.value = true;
            if(id.value > 0){
                store.dispatch('app-crm/updateSubscriber',{
                    title:title,
                    isPublic:isPublic.value,
                    is_public:isPublic.value?1:0,
                    user_selected:user_selected.value,
                    created_by:userData.id,
                    id:id.value
                })
                    .then(response => {
                     
                        //subscriber-list
                        if(response.data.success == true){
                            router.push('/crm/subscriber', () => {
                               toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: `Done`,
                                        icon: 'AlertTriangleIcon',
                                        variant: 'success',
                                        text: `You have successfully created.`,
                                    },
                                })
                            });
                        }else{
                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        }
                        show.value = false;
                
                    })
                    .catch((error) => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: error,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                        show.value = false;
                    });

            }else{
                store.dispatch('app-crm/addSubscriber',{
                    title:title,
                    isPublic:isPublic.value,
                    is_public:isPublic.value?1:0,
                    user_selected:user_selected.value,
                    created_by:userData.id
                })
                    .then(response => {
                     
                        //subscriber-list
                        if(response.data.success == true){
                            router.push('/crm/subscriber', () => {
                               toast({
                                    component: ToastificationContent,
                                    position: 'top-right',
                                    props: {
                                        title: `Done`,
                                        icon: 'AlertTriangleIcon',
                                        variant: 'success',
                                        text: `You have successfully created.`,
                                    },
                                })
                            });
                        }else{
                            toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        }
                        show.value = false;
                
                    })
                    .catch((error) => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: error,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                        show.value = false;
                    });
            }
           
          


        }

    }
    const getData = async ()=>{
        let response = await store.dispatch('app-crm/fetchsubscriberDetailData',{id:id.value});
        if(response.data.data){
            let data = response.data.data;
            isPublic.value = data.is_public?true:false;
            title.value = data.title;
          
          
            user_selected.value = data.users


        }
        selectAlled.value = true
        console.log('mail-data', response);
    }

    if(router.currentRoute.params.id){
        id.value = router.currentRoute.params.id;
        getData();
       
     

    }
    const fetchData = (ctx, callback)=>{
        const offset = perPage.value*(currentPage.value-1);
        let user_id = '';

        if(id > 0 && user_selected.value.length > 0){
            user_id =  user_selected.value.join(',');
            

        }
        console.log('aa',group_id.value);
        
        let looking = '';
        if(filter_type.value == 'enquiry'){
            if(filter_types.value){
                if(filter_types.value.id == 4){
                    looking = 'Rent';

                }
                if(filter_types.value.id == 3){
                    looking = 'Sale';

                }
                if(filter_types.value.id == 2){
                    looking = 'Sale & Rent';

                }

            }

        }
        users.value = []
        //Sale & Rent Sale  Rent
        store
        .dispatch('app-crm/fetchData', {
            //perpage: perPage.value,
            //offset:offset,
            keyword:searchQuery.value,
            group_email:filter_group.value?filter_group.value.id:'',
            zone_id:filter_zone.value?filter_zone.value.id:'',
            groupType: 'user',
            groupQuery:groupQuery.value,
            bedroom:filter_bedroom.value,
            bathroom:filter_bathroom.value,
            action_id:filter_types.value?filter_types.value.id:null,
            priceMin:filter_price_min.value?filter_price_min.value:null,
            priceMax:filter_price_max.value?filter_price_max.value:null,
            types:filter_type.value?filter_type.value:'property',
            looking:looking,
            group_id: group_id.value?group_id.value:'',
            property_name: property_name.value?property_name.value:'',
            subscriber_id:id.value

         
        })
        .then(response => {
            
            
            const data = response.data.data;
         
            totals.value = response.data.total
            users.value = response.data.data;
            users.value.slice(offset,offset+perPage.value);
            //console.log('user-resp',users.value);
        //  const { invoices, total } = response.data
            //callback(data)
            // callback(invoices)
            //totalInvoices.value = total
        })
        .catch(() => {
          /*  toast({
            component: ToastificationContent,
            props: {
                title: "Error fetching Owner' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })*/
        })
    }
      fetchData();

    return {
        group_id,
        property_name,
        selectAllData,
        refListTable,
        selectAlled,
        tableColumns,
        perPage,
        groupQuery,
        totals,
        currentPage,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        dataMeta,
        fetchData,
        user_selected,
        filter_group,
      filter_zone,
      filter_bedroom,
      filter_bathroom,
      filter_types,
      filter_price_min, 
      filter_price_max,
      filter_type,
      isPublic,
      SubmitForm,
      show,
      title,
      users,
      id,
      date_from,
      date_to
        
    }
}