<template>
<div>
    <b-overlay
        id="overlay-background"
        :show="show"
        :variant="variant"
        :opacity="opacity"
        :blur="blur"
        rounded="sm"
      >
     <b-card-actions action-collapse title="Manage Subscriber">
        <validation-observer ref="SubscriberRules">
           
              <b-row>
                 <b-col
                  cols="12"
                  md="6"
                  class="mb-md-0 mb-2"
                  >
                      <b-form-group>
                            <label>Title</label>
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="title"
                                >
                                <b-form-input
                                  
                                    v-model="title"
                                    placeholder="title"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                  
                  </b-col>
                   <b-col
                  cols="12"
                  md="6"
                  class="mb-md-0 mb-2"
                  >
                      <b-form-group>
                            <label>Is Public</label>
                            <validation-provider
                                #default="{ errors }"
                                rules="min:0"
                                name="isPublic"
                                >
                              <b-form-checkbox
                              v-model="isPublic"
                            
                              
                              class="custom-control-primary"
                              name="isPublic"
                              switch
                           
                            />
                        
                            
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                  
                  </b-col>
                  <b-col cols="12">
                        <b-button
                            variant="primary"
                            type="submit"
                            @click.prevent="validationForm"
                        >
                            Submit
                        </b-button>
                  </b-col>

              </b-row>
        
        </validation-observer>
        

     </b-card-actions>
    </b-overlay>
    <b-card-actions action-collapse title="Filters" v-if="!id">   
       
        <b-card-body>
            <b-row>
                <b-col
                cols="12"
                md="2"
                class="mb-md-0 mb-2"
                >
                <label>Group Email</label>
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select Group Mail"
                    v-model="filter_group"
                    :options="mailgroups"
                    class="w-100"
                  
                  
                />
                </b-col>
                <b-col
                cols="12"
                md="2"
                class="mb-md-0 mb-2"
                >
                <label>Type</label>
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    placeholder="Select Type"
                    v-model="filter_type"
                    :options="types"
                    class="w-100"
                  
                  
                />
                </b-col>
                
                <b-col
                cols="12"
                md="2"
                class="mb-md-0 mb-2"
                >
                <label>zone</label>
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    placeholder="Select Zone"
                    v-model="filter_zone"

                    :options="zones"
                    class="w-100"
                  
                  
                />
                </b-col>
                <b-col
                cols="12"
                md="1"
                xs="6"
                class="mb-md-0 mb-2"
                >
                    <label>Bedroom</label>
                    <b-form-input      placeholder="Bedroom" v-model="filter_bedroom" />
                </b-col>
                <b-col
                cols="12"
                md="1"
                xs="6"
                class="mb-md-0 mb-2"
                >
                    <label>Bathroom</label>
                     <b-form-input  v-model="filter_bathroom"    placeholder="BathRoom" />
                </b-col>
                 <b-col
                cols="12"
                md="2"
                class="mb-md-0 mb-2"
                >
                <label>Action</label>
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    placeholder="Select Type"
                    v-model="filter_types"
                    :options="actions_a"
                    class="w-100"
                  
                  
                />
                </b-col>
                 <b-col
                cols="12"
                md="1"
                xs="6"
                class="mb-md-0 mb-2"
                >
                    <label>Price Min</label>
                    <b-form-input  v-model="filter_price_min"    placeholder="Price Min" />
                </b-col>
                 <b-col
                cols="12"
                md="1"
                xs="6"
                class="mb-md-0 mb-2"
                >
                    <label>Price Max</label>
                    <b-form-input v-model="filter_price_max"      placeholder="Price Max" />
                </b-col>
              
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="2"
                class="mb-md-0 mt-2"
                >
                <label>Group</label>
                <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    placeholder="Select Group"
                    v-model="group_id"
                    :reduce="(option) => option.id"
                    :options="groups"
                    class="w-100"
                  
                  
                />
                </b-col>
                <b-col cols="12"
                md="2"
                class="mb-md-0 mt-2">
                  <label>Property Name</label>
                  <b-form-input
                      v-model="property_name"
                      class="d-inline-block mr-1"
                      placeholder="Property Name..."
                    />
                </b-col>
                <b-col cols="12"
                md="2"
                class="mb-md-0 mt-2">
                  <label>Date From</label>
                  <b-form-input
                      v-model="date_from"
                      class="d-inline-block mr-1"
                      placeholder="Property Name..."
                    />
                </b-col>
                <b-col cols="12"
                md="2"
                class="mb-md-0 mt-2">
                  <label>Date To</label>
                  <b-form-input
                      v-model="date_to"
                      class="d-inline-block mr-1"
                      placeholder="Property Name..."
                    />
                </b-col>

            </b-row>
        </b-card-body>
        
    </b-card-actions>
     <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
        
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="users"
 
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template #head(id)>
                            <b-form-checkbox  title="Toggle Select All" @input="selectAll" v-model="selectAlled" />
                            </template>
                       
     <template #cell(id)="data">
        <input type="checkbox"  v-model="user_selected" :value="data.item.id" />
          
        
      </template>
       <template #cell(is_active)="data">

      <b-form-checkbox
          v-if="data.item.is_active == 1"
          checked="true"
          class="custom-control-primary"
          name="is_active"
          switch
        />
      <b-form-checkbox
          v-else
          checked="false"
          class="custom-control-primary"
          name="is_active"
          switch
        />
    </template>
    <template #cell(name)="data">
      <b-link
        :to="{ name: 'clients-edit', params: { id: data.item.id } }"
        class="font-weight-bold d-block text-nowrap"
      >
        {{ data.item.firstname }} {{ data.item.lasttname }}
      </b-link>
      <small class="text-muted">{{ data.item.email }}</small>
     
      
     
      

    </template>
     <template #cell(avatar)="data">
             
               
                <b-img class="img_property" v-if="data.item.avatar_url "
                    :src="data.item.avatar_url"
                    fluid
                />
        <b-img  class="img_property" v-else
                    :src="'http://128.199.95.64:81/assets/images/default/property/picture.png'"
                    fluid
                />
            </template>
    <template #cell(actions)="data">
      <span>
        <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item :to="{ name: 'clients-edit', params: { id: data.item.id } }">
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span>Edit</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteconfirm(data.item.id)"> 
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </span>
        
    </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totals"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    
</div>    
</template>
<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg,BCardHeader,BCardBody,BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useSendMail from './useSendMail';
import crmStoreModule from '../crmStoreModule'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import Button from '../../components/button/Button.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

export default {
    components: {
        useSendMail,
        ValidationProvider, 
        ValidationObserver,
        crmStoreModule,
        BCard, BRow, BCol,BFormGroup , BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
        BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BImg,
        vSelect,
        BCardHeader,BCardBody,BOverlay,
        BCardActions,
        Button
    },
    
   
    setup() {
        const CRM_APP_STORE_MODULE_NAME = 'app-crm';
        if (!store.hasModule(CRM_APP_STORE_MODULE_NAME)) store.registerModule(CRM_APP_STORE_MODULE_NAME, crmStoreModule);
        onUnmounted(() => {
            if (store.hasModule(CRM_APP_STORE_MODULE_NAME)) store.unregisterModule(CRM_APP_STORE_MODULE_NAME)
        })
        const statusOptions = [
                                'Unknow',
                                'Sale & Rent',
                                'Sale',
                                'Rent',
                                ];
        const types = ['property', 'enquiry'];
        const {
            fetchClients,
            csvExport,
            tableColumns,
            perPage,
            currentPage,
            totals,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            groupQuery,
            deleteData,
            fetchData,
            refetchData,
            filter_group,
            filter_zone,
            filter_bedroom,
            filter_bathroom,
            filter_types,
            filter_type,
            selectAlled,
            user_selected,
            filter_price_min, 
            filter_price_max,
            SubmitForm,
            isPublic,
            selectAllData,
            show,
            group_id,
            property_name,
            title,
            users,
            id,
            date_from,
            date_to
        } = useSendMail()
        return {
          title,
            fetchClients,
            selectAlled,
            csvExport,
            tableColumns,
            perPage,
            currentPage,
            totals,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            groupQuery,
            deleteData,
            refetchData,
            statusOptions,
            filter_group,
            filter_zone,
            filter_bedroom,
            filter_bathroom,
            filter_types,
            filter_type,
            filter_price_min, 
            filter_price_max,
            filter_type,
            types,
            isPublic,
            user_selected,
            selectAllData,
            SubmitForm,
            fetchData,
            show,
            group_id,
            property_name,
            users,
            id,
            date_from,
            date_to
        }
    },
    data(){
        return {
          groups:[{id:'',name:'All Group'}],
            mailgroups:[],
            zones:[],
            actions_a:[],
          
             variant: 'light',
          opacity: 0.85,
          blur: '2px',
          variants: ['transparent', 'white', 'light', 'dark', 'primary', 'secondary', 'success', 'danger', 'warning', 'info'],
          blurs: [{ text: 'None', value: '' }, '1px', '2px', '5px', '0.5em', '1rem'],
           
         
         
        }
    },
    directives: {
      Ripple,
    },
    created(){
        this.getMailGroups();
        this.getZones();
        this.get_a();
        this.getGroups();
    },
   
    methods:{
      validationForm() {
        this.$refs.SubscriberRules.validate().then(async success => {
          if(success){
            this.SubmitForm(this.title);
          }
        });
      },
      
      pmails(value){
        this.pmails = value;
        if(value.length == 0){
            this.selectAlled = false;
        }
       
        this.add_tomail();
    },
     selectAll() {
      this.selectAlled != this.selectAlled;
       this.selectAllData(this.selectAlled);
      //console.log('fetchData',this.fetchData.value);
          
           /* this.pmails = [];
          
            if (this.selectAlled) {
               // console.log('ml',this.properties_selected);
               
               this.properties_selected.forEach(ps => {
                   this.pmails.push(ps.id);

               })
                    
                   
            }
            this.add_tomail();*/
        },
    add_tomail(){
          //properties_mail
          let selects = this.properties_selected;
          let pmails = this.user_selected;
         
          this.user_selected  = selects;
        this.user_selected  = pmails.map(function(value) {
             let index =  selects.findIndex(p => p.id === value);
            console.log('index',index);
            if(index > -1){  
                  return selects[index];
            }
              
        
            });

         // console.log('pmails',this.pmails);

      },
       async get_a(){
          const actions = await axios.get('/property-action/v1/list');
          this.actions_a = actions.data.data;

        },
        async getMailGroups(){
            let groups = await store.dispatch('app-crm/fetchGroupMail', {
                            limit:50,
                            offset:0,
                         });
            this.mailgroups = groups.data.data;
            

        },
        async getGroups(){
          axios.get('users/v1/groups/user')
          .then(res => { 
            console.log('res',res);
              this.groups = this.groups.concat(res.data.data); 
          })

      },
        async getZones(){
            let queryParams1 = {
                  perpage: 1000,
                  offset:0
                
                }
              var data1 = JSON.stringify(queryParams1);
              let zones = await axios.post('zone/v1/lists',data1);
              console.log('zones',zones);
              this.zones = zones.data.data
        }

    }
}

</script>
